import '../../../meta/lib/meta.js';
import '../../../../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';
import '../../../../debug/index.js';
import '../../../../../@glimmer/destroyable/index.js';
import '../../../../../@glimmer/validator/index.js';
import '../../../../../@glimmer/manager/index.js';
import { g as get } from '../../../../../shared-chunks/cache-DORQczuy.js';
import '../../../../../shared-chunks/env-mInZ1DuF.js';
import Mixin from '../../../../object/mixin.js';
import { assert } from '../../../../debug/lib/assert.js';
import inspect from '../../../../debug/lib/inspect.js';

/**
 @module ember
*/
const ActionSupport = Mixin.create({
  send(actionName, ...args) {
    (isDevelopingApp() && !(!this.isDestroying && !this.isDestroyed) && assert(`Attempted to call .send() with the action '${actionName}' on the destroyed object '${this}'.`, !this.isDestroying && !this.isDestroyed));
    let action = this.actions && this.actions[actionName];
    if (action) {
      let shouldBubble = action.apply(this, args) === true;
      if (!shouldBubble) {
        return;
      }
    }
    let target = get(this, 'target');
    if (target) {
      (isDevelopingApp() && !(typeof target.send === 'function') && assert(`The \`target\` for ${this} (${target}) does not have a \`send\` method`, typeof target.send === 'function'));
      target.send(...arguments);
    } else {
      (isDevelopingApp() && !(action) && assert(`${inspect(this)} had no action handler for: ${actionName}`, action));
    }
  }
});

export { ActionSupport as default };
