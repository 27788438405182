import { g as get } from '../../../shared-chunks/cache-DORQczuy.js';
export { X as ASYNC_OBSERVERS, J as ComputedDescriptor, C as ComputedProperty, D as DEBUG_INJECTION_FUNCTIONS, W as Libraries, Q as PROPERTY_DID_CHANGE, P as PROXY_CONTENT, Y as SYNC_OBSERVERS, $ as TrackedDescriptor, _ as _getPath, V as _getProp, j as activateObserver, G as addListener, v as addObserver, R as autoComputed, m as beginPropertyChanges, T as changeProperties, c as computed, z as defineDecorator, d as defineProperty, y as defineValue, E as descriptorForDecorator, b as descriptorForProperty, l as endPropertyChanges, e as expandProperties, I as flushAsyncObservers, x as hasListeners, h as hasUnknownProperty, p as inject, f as isClassicDecorator, U as isComputed, i as isElementDescriptor, O as libraries, F as makeComputedDecorator, Z as markObjectAsDirty, B as nativeDescDecorator, n as notifyPropertyChange, o as objectAt, N as on, H as removeListener, w as removeObserver, A as revalidateObservers, k as sendEvent, s as setClassicDecorator, t as tagForObject, a as tagForProperty, S as tracked } from '../../../shared-chunks/cache-DORQczuy.js';
export { g as getCachedValueFor } from '../../../shared-chunks/computed_cache-DmYKevAP.js';
export { a as alias } from '../../../shared-chunks/alias-sGqO9HFc.js';
import { deprecate } from '../../debug/index.js';
import { s as set } from '../../../shared-chunks/property_set-4etrFh8A.js';
export { _ as _setProp, t as trySet } from '../../../shared-chunks/property_set-4etrFh8A.js';
import { isDevelopingApp } from '@embroider/macros';
export { d as addArrayObserver, c as arrayContentDidChange, b as arrayContentWillChange, e as removeArrayObserver, a as replace, r as replaceInNativeArray } from '../../../shared-chunks/array-BIqISULL.js';
export { g as getProperties, s as setProperties } from '../../../shared-chunks/set_properties-BQFOCF2x.js';
export { cached } from '../../../@glimmer/tracking/index.js';
export { createCache, getValue, isConst } from '../../../@glimmer/validator/index.js';
export { N as NAMESPACES, a as NAMESPACES_BY_ID, b as addNamespace, f as findNamespace, c as findNamespaces, i as isNamespaceSearchDisabled, p as processAllNamespaces, d as processNamespace, r as removeNamespace, e as setNamespaceSearchDisabled, s as setUnprocessedMixins } from '../../../shared-chunks/namespace_search-DU-a-C1F.js';

/**
@module ember
*/

function deprecateProperty(object, deprecatedKey, newKey, options) {
  function _deprecate() {
    (isDevelopingApp() && !(false) && deprecate(`Usage of \`${deprecatedKey}\` is deprecated, use \`${newKey}\` instead.`, false, options));
  }
  Object.defineProperty(object, deprecatedKey, {
    configurable: true,
    enumerable: false,
    set(value) {
      _deprecate();
      set(this, newKey, value);
    },
    get() {
      _deprecate();
      return get(this, newKey);
    }
  });
}

const EACH_PROXIES = new WeakMap();
function eachProxyArrayWillChange(array, idx, removedCnt, addedCnt) {
  let eachProxy = EACH_PROXIES.get(array);
  if (eachProxy !== undefined) {
    eachProxy.arrayWillChange(array, idx, removedCnt, addedCnt);
  }
}
function eachProxyArrayDidChange(array, idx, removedCnt, addedCnt) {
  let eachProxy = EACH_PROXIES.get(array);
  if (eachProxy !== undefined) {
    eachProxy.arrayDidChange(array, idx, removedCnt, addedCnt);
  }
}

export { deprecateProperty, eachProxyArrayDidChange, eachProxyArrayWillChange, get, set };
