import '../../../@ember/object/index.js';
import '../../../@ember/-internals/meta/lib/meta.js';
import '../../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';
import '../../../@ember/debug/index.js';
import '../../../@glimmer/destroyable/index.js';
import '../../../@glimmer/validator/index.js';
import '../../../@glimmer/manager/index.js';
import { g as get } from '../../../shared-chunks/cache-DORQczuy.js';
import '../../../shared-chunks/env-mInZ1DuF.js';
import '../../../@ember/engine/instance.js';
import '../../../route-recognizer/index.js';
import '../../../shared-chunks/rsvp-DaQAFb0W.js';
import '../../../shared-chunks/unrecognized-url-error-zpz-JEoG.js';
import RoutingService from '../../../@ember/routing/lib/routing-service.js';
import '../../../@ember/controller/index.js';
import { assert } from '../../../@ember/debug/lib/assert.js';

/**
@module ember
*/
function currentPath(app) {
  (isDevelopingApp() && !(app.__container__) && assert('[BUG] app.__container__ is not set', app.__container__));
  let routingService = app.__container__.lookup('service:-routing');
  (isDevelopingApp() && !(routingService instanceof RoutingService) && assert('[BUG] service:-routing is not a RoutingService', routingService instanceof RoutingService));
  return get(routingService, 'currentPath');
}

export { currentPath as default };
