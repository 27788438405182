import '../../../@ember/object/index.js';
import '../../../@ember/debug/index.js';
import EmberRouter from '../../../@ember/routing/router.js';
import { isDevelopingApp } from '@embroider/macros';
import { assert } from '../../../@ember/debug/lib/assert.js';
import { g as get } from '../../../shared-chunks/cache-DORQczuy.js';
import '../../../@ember/-internals/meta/lib/meta.js';
import '../../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import '../../../@glimmer/destroyable/index.js';
import '../../../@glimmer/validator/index.js';
import '../../../@glimmer/manager/index.js';
import '../../../shared-chunks/env-mInZ1DuF.js';

/**
@module ember
*/
function currentURL(app) {
  (isDevelopingApp() && !(app.__container__) && assert('[BUG] app.__container__ is not set', app.__container__));
  let router = app.__container__.lookup('router:main');
  (isDevelopingApp() && !(router instanceof EmberRouter) && assert('[BUG] router:main is not a Router', router instanceof EmberRouter));
  let location = get(router, 'location');
  (isDevelopingApp() && !(typeof location !== 'string') && assert('[BUG] location is still a string', typeof location !== 'string'));
  return location.getURL();
}

export { currentURL as default };
