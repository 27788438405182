import '../../-internals/meta/lib/meta.js';
import '../../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';
import { info } from '../../debug/index.js';
import '../../../@glimmer/destroyable/index.js';
import '../../../@glimmer/validator/index.js';
import '../../../@glimmer/manager/index.js';
import { g as get } from '../../../shared-chunks/cache-DORQczuy.js';
import '../../../shared-chunks/env-mInZ1DuF.js';
import Controller from '../../controller/index.js';
import { assert } from '../../debug/lib/assert.js';

function generateControllerFactory(owner, controllerName) {
  let factoryManager = owner.factoryFor('controller:basic');
  (isDevelopingApp() && !(factoryManager !== undefined) && assert('[BUG] unexpectedly missing a factoryManager for `controller:basic`', factoryManager !== undefined)); // SAFETY: This is *not* safe, and the cast should be removed in favor of the
  // `assert()` below after altering *tests*. It is left in this state for the
  // moment in the interest of keeping type-only changes separate from changes
  // to the runtime behavior of the system, even for tests.
  let Factory = factoryManager.class;
  // assert(
  //   '[BUG] factory for `controller:main` is unexpectedly not a Controller',
  //   ((factory): factory is typeof Controller => factory === Controller)(Factory)
  // );

  Factory = Factory.extend({
    toString() {
      return `(generated ${controllerName} controller)`;
    }
  });
  let fullName = `controller:${controllerName}`;
  owner.register(fullName, Factory);
  return owner.factoryFor(fullName);
}

/**
  Generates and instantiates a controller extending from `controller:basic`
  if present, or `Controller` if not.

  @for Ember
  @method generateController
  @private
  @since 1.3.0
*/
function generateController(owner, controllerName) {
  generateControllerFactory(owner, controllerName);
  let fullName = `controller:${controllerName}`;
  let instance = owner.lookup(fullName);
  (isDevelopingApp() && !(instance instanceof Controller) && assert('Expected an instance of controller', instance instanceof Controller));
  if (isDevelopingApp()) {
    if (get(instance, 'namespace.LOG_ACTIVE_GENERATION')) {
      info(`generated -> ${fullName}`, {
        fullName
      });
    }
  }
  return instance;
}

export { generateController as default, generateControllerFactory };
