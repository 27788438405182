<template>
  <h2 class='pix-title-s'>Article 1. Preamble</h2>

  <p>
    The Pix “Groupement d’Intérêt Public” (French public interest group or “GIP”) approved by the decree of 27 April
    2017 giving approval of the agreement establishing the public interest group ‘Pix’, initially developed the Pix
    platform within the framework of a public project in partnership with the French Ministry of Education and Ministry
    of Higher Education, Research and Innovation, and continue its development with the GIP actual members and partners.
  </p>
  <p>
    The Pix platform is an online platform for assessing and certifying digital skills. Its aim is to help raise the
    general level of digital knowledge and skills and thus help prepare the digital transformation of society and the
    economy. It is based on the European DigComp reference framework, which defines digital skills on eight (8) levels
    and in five (5) competence areas:
  </p>
  <ul>
    <li>Information and data;</li>
    <li>Communication and collaboration;</li>
    <li>Content creation;</li>
    <li>Protection and security;</li>
    <li>Environment and digital technology.</li>
  </ul>
  <p>
    Users registered on the platform can take a certification exam of their skills at a centre approved by the GIP Pix.
  </p>
  <p>
    The GIP Pix makes available to centres and their referents or authorised members (hereinafter the « users »), the
    Pix Certif service accessible in Saas mode, for the purpose of organising certification examination sessions, in
    particularly management of registrations and editing of the documentation necessary for their implantation.
  </p>

  <h2 class='pix-title-s'>Article 2. Definitions</h2>

  <ul>
    <li>
      ‘centre’: refers to the legal person benefiting from this quality by the effect of the law or a regulation, or
      from a valid approval under a contract with the GIP Pix as a Pix Certification Centre, and having authorised the
      user to use the Pix Certif services;
    </li>
  </ul>
  <ul>
    <li>
      ’certification’: refers to the certification of digital skills defined by the decree of April 27, 2017, approving
      the constituent agreement of the public interest group Pix, as well as by the regulations in force and to come go
      through candidates registered with the centre in part of a certification session organised by the centre;
    </li>
  </ul>
  <ul>
    <li>
      ‘terms and conditions’: refers to these terms and conditions for the use of the Pix Certif platform;
    </li>
  </ul>
  <ul>
    <li>
      ‘data’: all the information of any kind provided by the organisation and/or the users under their full
      responsibility, hosted by the Pix GIP and intended to be processed with the framework of service implementation;
    </li>
  </ul>
  <ul>
    <li>
      ‘login credentials’: login and password entered by a user in a personal and confidential way when creating a user
      account, enabling access to the services from a secure connection to the Pix Certif platform;
    </li>
  </ul>
  <ul>
    <li>
      ‘Pix Certif’: refers de the web application of the GIP Pix, made available by the latter to the user in Saas mode
      and intended to allow the registration of candidates for certification, as well as the organisation and
      implementation of certification sessions by editing the documents necessary for this purpose;
    </li>
  </ul>
  <ul>
    <li>
      ‘Pix platform’: public platform for assessing and certifying digital skills based on an IT infrastructure made up
      of interfaces, database(s) and server(s) that is accessible online in SAAS mode. Its source code is under the open
      source licence GNU GPL V.3;
    </li>
  </ul>
  <ul>
    <li>
      ‘services’: access by the user – via the Internet, in SaaS mode – to the features of the Pix Certif platform as
      described in the Article ‘Presentation of the services’;
    </li>
  </ul>
  <ul>
    <li>
      ‘certification session‘: session during the certification centre will welcome candidates who will pass the Pix
      certification. The certification session is defined by a specific location (site and room), a date and a time.
    </li>
  </ul>
  <ul>
    <li>
      ‘user’: refers to a user authorised by the Centre, accessing and implementing the functionalities of the Pix
      Certif services, in his capacity as a referent of the Centre and/or a member of the Centre.
    </li>
  </ul>

  <h2 class='pix-title-s'>Article 3. Purpose</h2>

  <p>
    The purpose of these terms and conditions is to define the conditions for using and accessing the Pix Certif
    platform that is accessible at the aforementioned address.
  </p>

  <h2 class='pix-title-s'>Article 4. Acceptance and enforcement</h2>

  <h3 class='pix-title-xs'>4.1 Acceptance</h3>

  <p>
    The user may only use the Pix Certif platform and benefit from the services provided that these terms and conditions
    are accepted.
  </p>
  <p>
    Acceptance of these terms and conditions is confirmed by the user who is using the Pix Certif platform for the first
    time clicking on the button ‘I accept the terms and conditions’, after they have read them all. This click is proof
    that the user has acknowledged these stipulations and implies acceptance of these terms and conditions. The user has
    the option to save and print these terms and conditions using the standard functionalities of their browser or their
    computer.
  </p>
  <p>
    The user acknowledges that access to and use of the Pix Certif platform and the services provided requires
    observance of all the stipulations defined within these terms and conditions.
  </p>

  <h3 class='pix-title-xs'>4.2 Enforcement</h3>

  <p>
    These terms and conditions are legally binding to the user as soon as they have accepted them when creating a user
    account or above all when they first access the organisation space.
  </p>
  <p>
    The Pix GIP reserves the right to make all the changes they deem necessary and useful to these terms and conditions.
  </p>
  <p>
    These terms and conditions are enforceable throughout the duration of the use of the Pix Certif platform until new
    terms and conditions replace them.
  </p>
  <p>
    The Pix GIP undertakes to provide the user with all the new terms and conditions.
  </p>
  <p>
    Any use without reservations of the user account, or acceptance by means of a checkbox, after the changes of the
    conditions of use and information of the user or his organisation, is worth acceptance by the latter and his
    organisation of the new conditions of use.
  </p>
  <p>
    The user may at any time forego the use of the services, but remains responsible for any previous use.
  </p>

  <h2 class='pix-title-s'>Article 5. User account</h2>

  <h3 class='pix-title-xs'>Step 1.</h3>

  <p>The user fills in a web form on the Pix platform.</p>
  <p>
    The form shows the required data that must be filled in to create a user account (first name, surname, email address
    and password) with an asterisk.
  </p>
  <p>
    The user must enter a valid email address that will enable a confirmation email to be sent when his user account is
    created. Furthermore, they must choose a password that meets the following requirements:
  </p>
  <ul>
    <li>minimum of 8 characters;</li>
    <li>at least one lowercase letter;</li>
    <li>at least one uppercase letter;</li>
    <li>at least one digit.</li>
  </ul>
  <p>
    The user must confirm, by checking the box, that they have read and accepted the Pix platform terms and conditions.
  </p>

  <h3 class='pix-title-xs'>Step 2.</h3>

  <p>
    The user asks the centre to transfer to the GIP Pix a request to attach their user account on Pix Platform, to the
    Pix Certif service.
  </p>

  <h3 class='pix-title-xs'>Step 3.</h3>

  <p>
    The GIP Pix attaches the user account on the Pix platform to the Pix Certif services within the time necessary for
    the usual security checks, which will allow the user to access the services.
  </p>

  <h3 class='pix-title-xs'>Step 4.</h3>

  <p>
    The user logs into their user account and to Pix Certif services with their login credentials, it being recalled
    that they are personal and confidential.
  </p>
  <p>The user is solely responsible for ensuring the password remains confidential, and as a result, the consequences of
    involuntary disclosure to anyone.</p>
  <p>Any use of the user account with the password allocated to the user is presumed to originate exclusively from the
    user.</p>
  <p>The user will immediately report any theft of their password or any use by an unauthorised third party that he is
    aware of. This notification must be sent to: support@pix.fr.</p>
  <p>They can reset the password via the user account by clicking the “Forgot your password” tab then by entering their
    email address. An email will be sent to the user, enabling them to define a new password that must also comply with
    the aforementioned requirements.</p>

  <h2 class='pix-title-s'>Article 6. Description of services</h2>

  <p>Pix Certif enables the user to access the following services :</p>
  <ul>
    <li>
      management of certification sessions;
    </li>
    <li>
      management of registration;
    </li>
    <li>
      management of certification session minutes;
    </li>
    <li>
      incident reporting;
    </li>
    <li>access to the certification kit produced by the GIP Pix in order to facilitate the organisation of sessions and
      improve their quality (models, instructions,etc.).</li>
  </ul>

  <h2 class='pix-title-s'>Article 7. Access to Pix Certif</h2>

  <p>The services are normally available 24 hours a day, 7 days a week.</p>
  <p>The GIP Pix reserves the right to restrict access to the services either totally or partially, with an impact on
    their availability, in order to maintain the Pix Certif platform within the framework of work scheduled in advance,
    including servers and infrastructure implemented for the provision of services.</p>
  <p>
    The user will be responsible for monitoring the possibilities for upgrading the IT and transmission resources
    available to them so that these resources can be adapted to upgrades to the Pix Certif platform and other services
  </p>
  <p>
    If the Pix Certif platform is suspended or impossible to use, the user can always contact the Pix GIP’s support
    service to obtain information at the following address : support@pix.fr.
  </p>
  <p>In the event that GIP Pix has entered into a service level agreement with the organisation, the latter prevails.</p>

  <h2 class='pix-title-s'>Article 8. Technical features </h2>

  <p>
    The Pix GIP endeavours to provide a quality service. It enables users to use the services provided to them under the
    best conditions possible.
  </p>
  <p>
    The Pix GIP is bound, with respect to the user and in accordance with the relevant standards and usage, to an
    obligation of means in execution of the service.
  </p>
  <p>
    Due to the nature and complexity of the internet network, and in particular, its technical performance and the
    response times in consulting, querying or transferring information data, the Pix GIP makes every effort to enable
    access to and use of the platform, in accordance with industry standards. The Pix GIP cannot guarantee the
    accessibility or absolute availability of the platform enabling access to the service.
  </p>
  <p>
    The Pix GIP cannot be held responsible for the smooth operation of the user’s IT equipment as well as their access
    to the internet.
  </p>
  <p>
    The Pix GIP informs the user of the following technical prerequisites that are necessary to the smooth operation of
    the Pix Certif and the services:
  </p>
  <ul>
    <li>a web browser (Firefox, Internet Explorer 11 and above, Edge, Chrome, Safari or Opera) in a recent version (not
      dating back more than 2 years);</li>
    <li>a satisfactory internet connection.</li>
  </ul>

  <h2 class='pix-title-s'>Article 9. Management of certification sessions</h2>

  <p>
    It is up to the user to ensure with the centre(s) to which they are attached, at least 2 working days before the
    certification session, that they will have the means (computer equipment and network) to access the Pix Certif
    application to carry out all the essentials operations to allow the good proceedings of the certification sessions,
    under the conditions provided for the approval of their centre of attachment.
  </p>

  <h2 class='pix-title-s'>Article 10. Use</h2>

  <p>
    The user undertakes to use the services only under the conditions defined in these conditions of use and in
    addition:
  </p>
  <ul>
    <li>not to divert the use of the services for personal purposes or contrary to the normal use of the services;</li>
    <li>
      to respect any agreement with the GIP Pix determining the number of end users authorised according to the number
      of credits acquired;
    </li>
    <li>not to commit any act of infringement.</li>
  </ul>
  <p>
    The user is responsible with their centre for the use of their user account and services. They undertake to use them
    fairly, in compliance with these conditions of use, applicable laws and regulations, in particular laws relating to
    intellectual and industrial property, the protection of personal data and private life.
  </p>

  <h2 class='pix-title-s'>Article 11. Security</h2>

  <p>Any fraudulent access to the platform is prohibited and subject to criminal penalties.</p>
  <p>
    The GIP Pix makes its best efforts, in accordance with the industry standards, to secure the platform, given the
    complexity of the Internet. It cannot guarantee absolute security.
  </p>
  <p>The user may inform the GIP Pix of any failure of their user account by sending a notification to: support@pix.fr.</p>
  <p>
    The user agrees to take all the appropriate measures to regularly back up their own data and to protect their data
    and/or software and/or devices used against infection by any viruses on the internet network.
  </p>

  <h2 class='pix-title-s'>Article 12. Maintenance and support</h2>

  <h3 class='pix-title-xs'>12.1 Support and assistance to the user</h3>

  <p>
    The GIP Pix provides support of an exclusively technical nature to the user, who is responsible for reporting any
    malfunctions of the services observed by themselves.
  </p>
  <p>The user can reach GIP Pix technical support by email at the address support@pix.fr</p>
  <p>GIP Pix may offer different levels of assistance and support services to the user, under a separate contract.</p>

  <h3 class='pix-title-xs'>12.2 Progressive maintenance</h3>

  <p>
    The services are likely to evolve over time to take into account the technological upgrades and needs of
    organisations who use Pix Certif and users.
  </p>
  <p>The services may be upgraded at any time by the Pix GIP.</p>
  <p>
    It is the organisation and users' responsibility to upgrade their information system (hardware and software
    resources such as updating the operating system or browser) at their own expense, in the event of service upgrades
    that reasonably require it, to take changes to the technological standards into account.
  </p>
  <p>
    The Pix GIP is not responsible for damages of any kind that may result in the temporary unavailability of all or
    part of the services due to maintenance operations.
  </p>

  <h2 class='pix-title-s'>Article 13. Liability</h2>

  <h3 class='pix-title-xs'>13.1 User’s liability</h3>

  <p>
    The user accesses and uses the services at their own risk and under their responsibility and that of the centre
    which authorised him, in accordance with the French legislation as well as with these terms and conditions.
  </p>
  <p>
    The user and the centre are solely responsible for the legality of the data they communicate in the context of
    accessing and using the Pix Certif platform.
  </p>
  <p>The user is responsible along with the centre of the activity that occurs by their access to the services.</p>
  <p>
    The user will not disrupt other user’s access to and/or use of the Pix Certif platform or access third parties’ user
    spaces.
  </p>
  <p>
    Any operation carried out on Pix Certif with the user's login credentials is considered to have been done on behalf
    of the latter and incurs their responsibility.
  </p>
  <p>
    The user undertakes not to commit any action that could jeopardise the GIP Pix’s IT security or other users, in
    accordance with the article “Security”.
  </p>
  <p>
    The user undertakes not to interfere with or interrupt the normal operation of the user account and, more generally,
    the Pix Certif platform.
  </p>
  <p>
    The user undertakes with their centre to indemnify the GIP Pix, against complaints, action, proceedings or
    convictions of the latter resulting from the user’s failure to observe the terms and conditions.
  </p>

  <h3 class='pix-title-xs'>13.2 The Pix HIP’s liability</h3>

  <p>
    Given the diversity of the sources of data concerning the user, the conditions of its consultation and the time
    scales required to transmit them, the GIP Pix will do everything it can to guarantee the general quality and
    relevance of the information disseminated.
  </p>
  <p>
    The GIP Pix will endeavour to perform all the operations incumbent on it relating to the user’s account and the
    services, in accordance with the industry standards.
  </p>
  <p>
    The GIP Pix cannot be held liable for the quality of the services, as these are provided ‘as is’, which the user
    accepts.
  </p>
  <p>Furthermore, the GIP Pix cannot be held liable, unless otherwise agreed:</p>
  <ul>
    <li>
      GIP Pix may be held liable, under the provisions of common law, for any direct and foreseeable damages suffered by
      the user, excluding all indirect damages;
    </li>
    <li>
      indirect damages are considered to be, the loss of data, time, profits, turnover, margins, loss of orders,
      customers, operations, income, commercial actions or damage to the brand image, the expected results and the
      action of third parties, even if the GIP Pix was duly informed of the risk that such damages could occur;
    </li>
    <li>
      by mutual agreement with the user and irrespective of the causes, the Pix GIP’s liability shall be limited to the
      amount (excluding VAT) of the price paid by the customer in return for the services that gave rise to the incident
      during which the damage occurred;
    </li>
    <li>
      by common agreement, the Pix GIP’s liability cannot be incurred due to the services when they are provided free of
      charge.
    </li>
  </ul>
  <p>
    This clause remains applicable in the event all or part of this contract relationship is terminated or becomes null
    and void.
  </p>

  <h2 class='pix-title-s'>Article 14. Intellectual property rights</h2>

  <p>
    The GIP Pix is, and remains, the exclusive owner of the protected elements of the Pix platform and Pix Certif
    platform that are not under open source licence according to the terms of the French Intellectual Property Code,
    excluding any teaching resources or questions that are the intellectual property of third parties, if applicable.
  </p>
  <p>
    The GIP Pix’s property right extends, in particular and within this framework, to all the documentation and
    databases of the Pix and Pix Certif platforms, as well as their interfaces including test questions (list non
    exhaustive).
  </p>
  <p>
    These terms and conditions contract will under no circumstances be considered to transfer or assign the intellectual
    property rights of the Pix and Pix Certif platforms or the services to users.
  </p>
  <p>
    As a result, users will refrain from any action that may directly or indirectly infringe the GIP Pix or third
    parties’ intellectual property rights. In particular, they will refrain from editing, copying, reproducing,
    downloading, broadcasting, transmitting, commercially exploiting and/or distributing in any way whatsoever, insofar
    as they would respectively be subject to protection under an intellectual property right for their parts which are
    not under an Open Source licence
  </p>
  <p>Elements subject to an Open Source licence must be used in accordance with their licence.</p>
  <p>
    Only use of the services in accordance with their intended purpose is authorised. Any non-compliant use or use not
    explicitly authorised by the GIP Pix under these terms and conditions is illegal.
  </p>

  <h2 class='pix-title-s'>Article 15. Hyperlinks</h2>

  <p>
    The Pix GIP reserves the right to put in place hyperlinks on the Pix Certif giving access to web pages other than
    those of the Pix and Pix Certif.
  </p>
  <p>
    The Pix GIP declines any liability as to the content of the information provided on these websites through the
    activation of hyperlinks.
  </p>
  <p>Each user accesses third-party websites under their sole and entire responsibility.</p>
  <p>
    Any hyperlink on a third-party website that redirects to any of the internet pages of the Pix platform requires
    prior and written consent from the Pix GIP.
  </p>

  <h2 class='pix-title-s'>Article 16. Personal data</h2>

  <h3 class='pix-title-xs'>16.1 Information of use</h3>

  <p>
    As part of its relations with users, GIP Pix, as data controller, may be required to process the following personal
    data relating to the user’s identity: surname(s), first name(s), function, qualification, professional email
    address, telephone number, entity or centre of attachment, experience and CV, login credentials, (excluding any ID
    listed as sensitive data within the meaning of the French data protection authority (CNIL)), usage logs.
  </p>
  <p>
    These are the data processed by Pix as part of the creation of the user account on pix.fr, independently of the data
    processed specifically by the user's centre, in that case, the centre is the sole data controller.
  </p>
  <p>
    Within the framework of creating a user account, the user is informed that the information marked with an asterisk
    on the collection form is required. Otherwise, the user's request may not be processed or its processing may be
    delayed.
  </p>
  <p>
    The data is processed for the internal needs of the GIP Pix, under the conditions of the Privacy Policy, which the
    user has read and accepted beforehand.
  </p>
  <p>When creating the Pix Certif space, the user and his centre undertake to:</p>
  <ul>
    <li>
      send GIP Pix the email address of the organisation's referent or Data Protection Officer and notify GIP Pix of any
      change in these contact details, if any, via the form dedicated to the creation of this space;
    </li>
    <li>
      respect the data protection regulations and the obligations of the centre as data controller, in particular
      respect for the information of the persons concerned.
    </li>
  </ul>

  <h3 class='pix-title-xs'>16.2 Subcontracting</h3>

  <p>
    The user is likely to process the personal data of candidates as a subcontractor of the centre (or a second-tier
    subcontractor of the GIP Pix), the centre itself being a subcontractor of GIP Pix.
  </p>
  <p>
    The term subcontractor within the meaning of the data protection regulations and in particular of EU Regulation
    2016/679 and of this clause, means the natural person (in this case the user as a subcontractor second-tier) or
    legal entity (the centre as a first-tier subcontractor) which processes personal data on behalf of the data
    controller, the GIP Pix.
  </p>
  <p>
    It is up to the user to respect the contractual agreement provided between him and the centre in accordance with
    article 28 of EU Regulation 2016/679, having at least the same level of guarantee as the contractual agreement
    between the centre and the GIP Pix.
  </p>
  <p>The user can request communication of this agreement from the centre.</p>

  <h2 class='pix-title-s'>Article 17. Cookies</h2>

  <p>The user is informed that, when accessing the Pix platform, one or more cookies may be automatically installed on
    his terminal.</p>
  <p>
    The cookie constitutes a block of data which is used to record information relating to the navigation of the user on
    a website or an application.
  </p>
  <p>
    The Pix platform only uses cookies for the purposes of statistical monitoring of visits to pix.fr and pix.org
    (audience measurement) and the configuration in place benefits from the exemption from obtaining consent, to the
    extent where it complies with the recommendations of the CNIL.
  </p>

  <h2 class='pix-title-s'>18. Termination</h2>

  <h3 class='pix-title-xs'>18.1 De-registration</h3>

  <p>
    Access to the services by the user is effective as long as the user logs in to the Pix Certif platform, uses its
    services and is authorised by the centre to which he is attached to use the services. When a user has not logged in
    or has not used their account for a period of five (5) years, the GIP Pix can archive the data concerning them and
    reactivate their account on request.
  </p>
  <p>
    Incase of withdrawal of their authorisation by the centre, to which they are attached, the user undertakes to
    refrain from any access and any use of the services.
  </p>

  <h3 class='pix-title-xs'>18.2 Suspension and exclusion</h3>

  <p>
    Should the user fail to meet the obligations of these terms and conditions, the GIP Pix reserves the right, without
    compensation or reimbursement, to suspend access to the services by their centre until the cause of suspension has
    been removed, eight (8) days after the sending the user an email requesting them to comply with these terms and
    conditions.
  </p>
  <p>
    In the event of repeated failure by the user to meet the obligations of these terms and conditions, the GIP Pix
    reserves the right, without compensation or reimbursement, eight (8) days after sending the user an email requesting
    them to comply with these terms and conditions that has remained without response, to terminate the user's
    registration and to terminate access to his user account or to prohibit access to all or part of the services at his
    centre, without prejudice to any action under common law which may be taken against them.
  </p>
  <p>
    In the event of a serious failure by the user to meet obligations of these terms and conditions, the GIP Pix
    reserves the right, without compensation or reimbursement, to terminate the user's registration and to terminate
    access to their user account or to prohibit access to all or part of the services at its centre, without prejudice
    to any action under common law which may be taken against them.
  </p>

  <h2 class='pix-title-s'>19. Agreement of proof</h2>

  <p>Online acceptance of these terms and conditions electronically between the parties has the same evidential value as
    the agreement on paper.</p>
  <p>
    Computer registers stored on the Pix GIP’s IT systems will be stored under reasonable security conditions and
    considered as proof of the communications between the parties. They will be admissible until the contrary is proven.
  </p>
  <p>
    The terms and conditions of the Pix Certif platform are archived on a reliable and durable medium that can be
    produced as evidence.
  </p>

  <h2 class='pix-title-s'>20. Invalidity</h2>

  <p>
    If one or more stipulations of these terms and conditions are held to be invalid or declared as such pursuant to the
    application of a law or a regulation, or following the final ruling by a court with jurisdiction, the other
    stipulations shall retain their full force and scope.
  </p>

  <h2 class='pix-title-s'>21. Applicable law</h2>

  <p>
    These terms and conditions are governed by French law. This applies to the rules of substance and rules of form,
    whatever the location in which the substantive or ancillary obligations are performed.
  </p>
</template>
