<template>
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
</template>
