import { classify, dasherize } from '../-internals/string/index.js';
import EmberObject from '../object/index.js';
import '../-internals/meta/lib/meta.js';
import '../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import '@embroider/macros';
import './index.js';
import '../../@glimmer/destroyable/index.js';
import '../../@glimmer/validator/index.js';
import '../../@glimmer/manager/index.js';
import '../../shared-chunks/cache-DORQczuy.js';
import '../../shared-chunks/env-mInZ1DuF.js';
import typeOf from '../utils/lib/type-of.js';
import '../-internals/runtime/lib/mixins/registry_proxy.js';
import '../-internals/runtime/lib/mixins/container_proxy.js';
import '../-internals/runtime/lib/mixins/comparable.js';
import '../-internals/runtime/lib/mixins/action_handler.js';
import '../-internals/runtime/lib/mixins/-proxy.js';
import '../enumerable/mutable.js';
import '../-internals/runtime/lib/mixins/target_action_support.js';
import '../-internals/runtime/lib/ext/rsvp.js';
import { getOwner } from '../-internals/owner/index.js';
import Namespace from '../application/namespace.js';

/**
@module @ember/debug/container-debug-adapter
*/

/**
  The `ContainerDebugAdapter` helps the container and resolver interface
  with tools that debug Ember such as the
  [Ember Inspector](https://github.com/emberjs/ember-inspector)
  for Chrome and Firefox.

  This class can be extended by a custom resolver implementer
  to override some of the methods with library-specific code.

  The methods likely to be overridden are:

  * `canCatalogEntriesByType`
  * `catalogEntriesByType`

  The adapter will need to be registered
  in the application's container as `container-debug-adapter:main`.

  Example:

  ```javascript
  Application.initializer({
    name: "containerDebugAdapter",

    initialize(application) {
      application.register('container-debug-adapter:main', require('app/container-debug-adapter'));
    }
  });
  ```

  @class ContainerDebugAdapter
  @extends EmberObject
  @since 1.5.0
  @public
*/
class ContainerDebugAdapter extends EmberObject {
  constructor(owner) {
    super(owner);
    this.resolver = getOwner(this).lookup('resolver-for-debugging:main');
  }

  /**
    The resolver instance of the application
    being debugged. This property will be injected
    on creation.
     @property resolver
    @public
  */
  resolver;

  /**
    Returns true if it is possible to catalog a list of available
    classes in the resolver for a given type.
     @method canCatalogEntriesByType
    @param {String} type The type. e.g. "model", "controller", "route".
    @return {boolean} whether a list is available for this type.
    @public
  */
  canCatalogEntriesByType(type) {
    if (type === 'model' || type === 'template') {
      return false;
    }
    return true;
  }

  /**
    Returns the available classes a given type.
     @method catalogEntriesByType
    @param {String} type The type. e.g. "model", "controller", "route".
    @return {Array} An array of strings.
    @public
  */
  catalogEntriesByType(type) {
    let namespaces = Namespace.NAMESPACES;
    let types = [];
    let typeSuffixRegex = new RegExp(`${classify(type)}$`);
    namespaces.forEach(namespace => {
      for (let key in namespace) {
        if (!Object.prototype.hasOwnProperty.call(namespace, key)) {
          continue;
        }
        if (typeSuffixRegex.test(key)) {
          let klass = namespace[key];
          if (typeOf(klass) === 'class') {
            types.push(dasherize(key.replace(typeSuffixRegex, '')));
          }
        }
      }
    });
    return types;
  }
}

export { ContainerDebugAdapter as default };
